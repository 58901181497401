import React from 'react';
import styled from 'styled-components';

const DescriptionContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.yellow[50]};
  padding: 16px;
  font-size: ${({ theme }) => theme.font_size.sm};
  color: ${({ theme }) => theme.colors.yellow[800]};
  font-weight: ${({ theme }) => theme.font_weight.regular};
  border-radius: 6px;
`;

const Description = ({ context }) => {
  return <DescriptionContainer>{context}</DescriptionContainer>;
};
export default Description;
