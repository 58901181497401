import { format } from 'date-fns';
import {
  isNationalIdentificationNumberValid,
  isResidentCertificateNumberValid,
  isNewResidentCertificateNumberValid,
} from 'taiwan-id-validator';
import { t } from 'src/i18n/config';

export const toBirthString = (newDate) => {
  return format(newDate, 'yyyy/MM/dd');
};

export const genderOptions = [
  {
    label: t('male'),
    value: 'M',
  },
  {
    label: t('female'),
    value: 'F',
  },
];

export const idTypes = [
  {
    id: 'id-type-option-tw-id',
    label: t('idNumber'),
    value: 'twID',
  },
  {
    id: 'id-type-option-pd-id',
    label: t('passportNumber'),
    value: 'pdID',
  },
];

export const studIdNumberIdentify = (idNumber = '', idType = 'twID') => {
  const studIdNumber = idNumber.toUpperCase();

  if (idType === 'twID') {
    return (
      isNationalIdentificationNumberValid(studIdNumber) ||
      isResidentCertificateNumberValid(studIdNumber) ||
      isNewResidentCertificateNumberValid(studIdNumber)
    );
  }
  if (idType === 'pdID') {
    return studIdNumber.length >= 6;
  }
};
