import React, {
  useMemo,
  useReducer,
  useEffect,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { createSelf, updatePatient } from '../../../services/user.service';
import {
  studIdNumberIdentify,
  idTypes,
  genderOptions,
} from '../../../utils/user';
import { getURLSearchParamByKey } from 'src/utils';
import { t } from 'src/i18n/config';
import {
  DatePicker,
  TextField,
  RadioGroup,
  InputContainer,
} from '../components/Fields';
import Button from '../components/Button';
import Popup from '../components/Popup';
import Description from '../components/Description';
import { deleteMember } from 'src/services/user.service';

const InputField = {
  NAME: 'name',
  ID_NUM: 'idNum',
};

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const defaultState = {
  info: {
    name: '',
    birth: '', // format -> '1990/01/01'
    idNum: '',
    idType: 'twID',
    gender: 'M',
  },
  errInput: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setname':
      return {
        ...state,
        info: {
          ...state.info,
          name: action.payload,
        },
      };
    case 'setidNum':
      return {
        ...state,
        info: {
          ...state.info,
          idNum: (action.payload || '').toUpperCase(),
        },
      };
    case 'setbirth':
      return {
        ...state,
        info: {
          ...state.info,
          birth: action.payload,
        },
      };
    case 'gender':
      return {
        ...state,
        info: {
          ...state.info,
          gender: action.payload,
        },
      };
    case 'userChanged':
      return {
        ...state,
        info: {
          ...state.info,
          ...action.payload,
        },
      };
    case 'validate':
      return {
        ...state,
        errInput: action.payload,
      };
    case 'idType':
      return {
        ...state,
        info: {
          ...state.info,
          idType: action.payload,
        },
      };
    default:
      break;
  }
};

const isValid = (errInput) => {
  if (Object.keys(errInput).length !== 0) {
    return false;
  }
  return true;
};

const Create = ({ user, idNumLocked, bindingPhoneNumber }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const showPassport = getURLSearchParamByKey('passport') === 'true';
  const generateMemberID =
    getURLSearchParamByKey('generateMemberID') === 'true';
  const goBack = getURLSearchParamByKey('goBack');

  const [state, dispatch] = useReducer(
    reducer,
    Object.keys(user).length > 0
      ? { info: { ...defaultState.info, ...user }, errInput: {} }
      : defaultState
  );

  const idOptions = useMemo(() => {
    if (idNumLocked) {
      return idTypes.filter(({ value }) => value === state.info.idType);
    }
    return showPassport
      ? idTypes
      : idTypes.filter(({ value }) => value !== 'pdID');
  }, [showPassport, idNumLocked, state.info.idType]);

  const selectedIdType = useMemo(() => {
    return idOptions.find(({ value }) => value === state.info.idType) || {};
  }, [idOptions, state.info.idType]);

  const checkValidation = useCallback(
    (user, idType) => {
      const errInput = {};
      if (
        !generateMemberID &&
        !studIdNumberIdentify(state.info.idNum, idType)
      ) {
        errInput[InputField.ID_NUM] = `${t('enterCorrectIdType')}${
          selectedIdType.label
        }`;
      }
      if (state.info.name === '') {
        errInput[InputField.NAME] = t('nameFormatError');
      }
      if (state.info.birth === '' || state.errInput['birth']) {
        errInput['birth'] = t('selectOption');
      }
      dispatch({ type: 'validate', payload: errInput });

      return errInput;
    },
    [
      generateMemberID,
      selectedIdType.label,
      state.errInput,
      state.info.birth,
      state.info.idNum,
      state.info.name,
    ]
  );

  const successCallback = () => {
    if (goBack) {
      window.location.replace(document.referrer);
    } else {
      navigate('/users/create/success?from=create', { replace: true });
    }
  };

  const handleClick = async () => {
    const errInput = checkValidation(user, state.info.idType);
    if (!isValid(errInput)) return;
    if (generateMemberID && !state.info.idNum) {
      let phone = user.phone || bindingPhoneNumber;
      if (phone.startsWith('+886')) {
        phone = phone.replace('+886', '0');
      }
      state.info.idType = 'memberID';
      state.info.idNum = state.info.name + phone;
    }
    if (user.id) {
      try {
        await updatePatient({
          id: user.id,
          ...state.info,
        });
        successCallback();
      } catch (error) {
        alert(error.message);
      }

      return;
    }
    createSelf(state.info)
      .then(() => {
        successCallback();
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const handleChange = (e) => {
    const changed = e.target.name;
    dispatch({ type: 'set' + changed, payload: e.target.value });
  };

  const handleDelete = () => {
    deleteMember(state.info)
      .then(() => {
        navigate('/users/create/success?from=delete', { replace: true });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const handleBirthChange = (value) => {
    dispatch({ type: 'validate', payload: { ...state.errInput, birth: '' } });
    dispatch({ type: 'setbirth', payload: value });
  };

  const handleIDTypeChange = (idType) => {
    dispatch({ type: 'idType', payload: idType });
  };

  const handleGenderChange = (gender) => {
    dispatch({ type: 'gender', payload: gender });
  };

  useEffect(() => {
    if (user) {
      dispatch({ type: 'userChanged', payload: user });
    }
  }, [user]);
  if (showDeleteModal) {
    return (
      <Popup
        type="warning"
        title={t('deleteDataTitle')}
        subtext={t('deleteDataSubtext')}
      >
        <Button maxWidth="343px" variant="alert" onClick={handleDelete}>
          {t('deleteDataTitle')}
        </Button>
        <Button
          maxWidth="343px"
          variant="text"
          onClick={() => setShowDeleteModal(false)}
        >
          返回
        </Button>
      </Popup>
    );
  }
  return (
    <InputContainer>
      <Description context={t('serviceDataDescription')} />
      <TextField
        label={'姓名'}
        name={InputField.NAME}
        onChange={handleChange}
        value={state.info.name}
        placeholder={t('enterYourName')}
        error={state.errInput[InputField.NAME]}
      />
      <DatePicker
        label="生日"
        value={state.info.birth}
        handleOnchange={handleBirthChange}
        error={state.errInput['birth']}
      />
      {!generateMemberID && (
        <RadioContainer>
          <RadioGroup
            options={idOptions}
            defaultValue={state.info.idType}
            onChange={handleIDTypeChange}
          />
          <TextField
            name={InputField.ID_NUM}
            disabled={idNumLocked}
            onChange={handleChange}
            value={state.info.idNum}
            error={state.errInput[InputField.ID_NUM]}
            placeholder={selectedIdType.label}
          />
          {(selectedIdType.value === 'pdID' || generateMemberID) && (
            <RadioGroup
              options={genderOptions}
              defaultValue={state.info.gender}
              onChange={handleGenderChange}
            />
          )}
        </RadioContainer>
      )}
      <div className="buttons">
        <Button onClick={handleClick}>儲存更新</Button>
        {user?.idNum && (
          <Button variant="text" onClick={() => setShowDeleteModal(true)}>
            {t('deleteDataTitle')}
          </Button>
        )}
      </div>
    </InputContainer>
  );
};

Create.proptTypes = {
  idNumLocked: PropTypes.string,
};

export default Create;
